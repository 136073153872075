@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Annifont";
  src: url("../public/pepfonts/annifont.ttf") format("opentype");
}
@font-face {
  font-family: "Cushy";
  src: url("../public/pepfonts/Cushy Bold.otf") format("opentype");
}
@font-face {
  font-family: "Cushy_W01";
  src: url("../public/pepfonts/Cushy W01 Regular.ttf") format("opentype");
}
@font-face {
  font-family: "PepHandwriting";
  src: url("../public/pepfonts/PepHandwriting-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "PepHandwriting-Regular";
  src: url("../public/pepfonts/PepHandwriting-Regular (1).ttf")
    format("opentype");
}
@font-face {
  font-family: "PepMyanmar";
  src: url("../public/pepfonts/PepMyanmar-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "PepMyanmar-Regular";
  src: url("../public/pepfonts/PepMyanmar-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  src: url("../public/pepfonts/Poppins-SemiBold.ttf") format("opentype");
}

:root {
  --primary-color: #ed1b24;
  --text-color: #6e4345;
}

@layer base {
  html {
    @apply scroll-smooth;
  }

  .heading-text {
    @apply text-[60px] font-[Annifont];
  }

  .header-default {
    @apply text-[24px] text-[var(--text-color)] font-[Cushy];
  }
}

* {
  box-sizing: border-box;
  list-style: none;
}

.product-hero {
  height: 40vh;
  background-image: url(../public/images/bannerbg.jpg);
  background-position: center;
  background-size: cover;
  margin-top: 30px;
}

.product-item-category {
  color: var(--text-color);
  font-family: "Cushy_W01";
  border: 2px solid #fff;
}

.product-item-category:hover {
  border: 2px solid var(--primary-color);
}

.product-item-category:hover .ball {
  background-color: var(--primary-color);
}

.product-card-subheader {
  color: var(--text-color);
  font-family: "Cushy";
  font-size: 16px;
}

.product-footer {
  font-family: "Cushy W01";
}

.profile-menu {
  height: 90vh;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 16px;
}

.acc-menu {
  color: var(--text-color);
  font-family: "Cushy";
  font-weight: 700;

  padding: 30px 10px;
}

.acc-menu:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.acc-menu:last-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.acc-menu:hover {
  color: var(--primary-color);
}

.profile-container {
  width: 120px;
  height: 120px;

  align-self: center;

  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 40px;
  position: relative;
}

.profile-container .edit-icon {
  background-color: #008ace;

  border-radius: 50%;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 10px;

  cursor: pointer;
}

.accDetail-form-label {
  color: var(--text-color);
  font-family: "Poppins";
  font-weight: 600;
}

textarea {
  resize: none;
}

.cart-container {
  box-shadow: 0px 12px 36.3px 0px rgba(0, 0, 0, 0.15);
}

.custom-form-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #919eab52;
  border-radius: 0.25rem;
  outline: none;

  position: relative;
}

.custom-form-checkbox::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 0.2rem;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.custom-form-checkbox:checked::before {
  background-color: var(--text-color);
}

.custom-form-checkbox:checked {
  border-color: var(--text-color);
}

.custom-signupform-radio {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--text-color);

  position: relative;
}

.custom-signupform-radio::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.custom-signupform-radio:checked::before {
  background-color: var(--text-color);
}

.custom-registerform-radio {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--text-color);

  position: relative;
}

.custom-registerform-radio::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.custom-registerform-radio:checked {
  border-color: var(--primary-color);
}

.custom-registerform-radio:checked::before {
  background-color: var(--primary-color);
}

.number-input {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.number-input button {
  background: #fff;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.number-input input {
  text-align: center;
  width: 50px;
  border: none;
}
